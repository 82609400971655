import { useState, useMemo, useEffect } from "react";
import MaterialTable from "../../components/MaterialTable";
import { Box, Button, Switch, Avatar, Chip, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import moment from "moment";
import axios from "axios";
import dotenv from "react-dotenv";
import { ExportToCsv } from "export-to-csv";
import SelectPathologyAndMedicalGroup from "../../components/SelectPathologyAndMedicalGroup";
import withMessages from "../../components/WithMessages";
import { useTranslation } from "react-i18next";

const PatientsIndexPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [patients, setPatients] = useState([]);
  const [patientsToExport, setPatientsToExport] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isError2, setIsError2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [pendingPatients, setPendingPatients] = useState([]);
  const [selectedPathology, setSelectedPathology] = useState(null);
  const [selectedMedicalGroup, setSelectedMedicalGroup] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [rowCount2, setRowCount2] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [pagination2, setPagination2] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        id: "name",
        accessorKey: "name",
        header: t("name"),
        size: 150,
        Cell: (cell) => (
          <Box display={"flex"}>
            <Avatar
              alt="avatar"
              src={cell.row.original?.avatar}
              sx={{
                width: "50px",
                height: "50px",
                border: 4,
                backgroundColor: "#D9D9D9",
                borderColor: "white",
              }}
            />
            <Box item xs={9} sx={{ mt: 2 }}>
              {cell.row.original.name}
            </Box>
          </Box>
        ),
      },
      {
        id: "lastname",
        accessorKey: "lastname",
        header: t("lastname"),
        key: "lastname",
        size: 100,
      },
      {
        id: "phone",
        accessorKey: "phone",
        header: t("phone"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 150,
      },
      {
        id: "email",
        accessorKey: "email",
        header: t("email"),
        key: "email",
      },
      {
        id: "medical-group",
        accessorKey: "medicalGroup",
        header: t("medicalGroup"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 150,
      },
      {
        id: "pathology",
        accessorKey: "pathology",
        header: t("pathology"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 100,
      },
      {
        id: "status",
        accessorKey: "status",
        header: t("state"),
        size: 100,
        Cell: (cell) => (
          <Chip
            sx={
              cell.row.original.status
                ? [styles.correctChip, styles.chip]
                : [styles.wrongChip, styles.chip]
            }
            label={cell.row.original.status ? "Activo" : "Inactivo"}
          />
        ),
      },
      {
        id: "actions",
        accessorKey: "actions",
        header: t("actions"),
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: (cell) =>
          ["superadmin", "admin-institution", "doctor"].some(
            r => props.role.includes(r)
          ) ? (
            <>
              <Switch
                onChange={() => {
                  handleStatusChange(
                    cell.row.original.status,
                    cell.row.original.id
                  );
                }}
                checked={cell.row.original.status}
              ></Switch>
              <IconButton
                onClick={() => navigate(`/patients/${cell.row.original.id}`)}
                aria-label="view"
              >
                <img src="/view.png" alt="view" />
              </IconButton>
            </>
          ) : (
            <></>
          ),
      },
    ],
    [navigate, t] 
  );

  const columns2 = useMemo(
    () => [
      {
        id: "created_at",
        Header: t("start_date_registration"),
        accessorKey: "created_at",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 150,
        Cell: (cell) => (
          <>{moment(cell.row.original.created_at).format("DD-MM-YYYY")}</>
        ),
      },
      {
        id: "user_email",
        Header: t("email"),
        accessorKey: "user_email",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 150,
      },
      {
        id: "phone",
        Header: t("phone"),
        accessorKey: "phone",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 150,
      },
    ],
    [navigate, t] 
  );

  const csvOptions = {
    fieldSeparator: ";",
    filename: "Listado de pacientes",
    decimalSeparator: ",",
    useBom: true,
    useKeysAsHeaders: false,
    showLabels: true,
    headers: columns.map((c) => c.header).slice(0, -1),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    getPatients(selectedPathology, selectedMedicalGroup, true)
  };

  useEffect(()=>{
    if (patientsToExport.length > 0) {
      let dataToExport = patientsToExport.map((row) => {
        return [
          row.name,
          row.lastname,
          row.phone,
          row.email,
          row.medicalGroup,
          row.pathology,
          row.status? "Activo" : "Inactivo",
        ];
      });
      csvExporter.generateCsv(dataToExport);
    }
  }, [patientsToExport]) 

  const refreshFilters = () => {
    setColumnFilters([]);
    setGlobalFilter("");
    setSorting([]);
  };

  const handleStatusChange = (status, patientId) => {
    if (!status) {
      axios
        .post(
          `/institutions/v1/memberships/${patientId}/activate`,
          {},
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then(() => {
          setSelectedPathology((newPathology) => {
            getPatients(newPathology, selectedMedicalGroup);
            refreshFilters();
          });
        })
        .catch(() => {
          setIsError(true);
        });
    } else {
      axios
        .delete(`/institutions/v1/memberships/${patientId}/delete`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then(() => {
          setSelectedPathology((newPathology) => {
            getPatients(newPathology, selectedMedicalGroup);
            refreshFilters();
          });
        })
        .catch(() => {
          setIsError(true);
        });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedPathology) {
          const url = new URL(
            "/institutions/v1/enroll-requests",
            dotenv.API_URL
          );
          url.searchParams.set("page", `${pagination2.pageIndex + 1}`);
          url.searchParams.set("size", `${pagination2.pageSize}`);
          url.searchParams.set("pathology_id", selectedPathology);
          url.searchParams.set("medical_group_id", selectedMedicalGroup);
          const response = await axios.get(url, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          });

          setPendingPatients(response.data.enrollRequests.data);
          setRowCount2(response.data.enrollRequests.total);
          setIsError2(false);
        }
      } catch (error) {
        setIsError2(true);
      }
    };

    fetchData();
  }, [selectedMedicalGroup, pagination2, selectedPathology]);

  const getPatients = (pathologyId, medicalGroupId, exportCSV = false) => {
    if (pathologyId != null && medicalGroupId != null) {
      const fetchData = async () => {
        if (!exportCSV) {
          setPatients([]);
          setIsLoading(true);
          setIsRefetching(true);
          setIsError(false);
        }
        const url = new URL("/institutions/v1/memberships", dotenv.API_URL);
        url.searchParams.set("page", `${pagination.pageIndex + 1}`);
        url.searchParams.set("size", `${pagination.pageSize}`);
        url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
        url.searchParams.set("globalFilter", globalFilter ?? "");
        url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
        url.searchParams.set("pathologyId", pathologyId);
        url.searchParams.set("medicalGroupId", medicalGroupId);
        url.searchParams.set("exportCSV", exportCSV);

        axios
          .get(url.href, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((response) => {
            if (!exportCSV) {
              if (response.data.data) {
                setPatients(response.data?.data);
                setRowCount(response.data?.total);
                setIsError(false);
              } 
            } else {
              setPatientsToExport(response.data);
            }
          })
          .catch(() => setIsError(true))
          .finally(() => {
            setIsLoading(false);
            setIsRefetching(false);
          });
      };
      fetchData();
    }
  };

  useEffect(() => {
    if (selectedPathology !== "") {
      getPatients(selectedPathology, selectedMedicalGroup);
    }
  }, [
    selectedMedicalGroup,
    columnFilters,
    globalFilter,
    pagination,
    sorting,
    user,
    t 
  ]);

  const styles = {
    chip: {
      borderRadius: "5px",
      height: "22px",
    },
    correctChip: {
      backgroundColor: "#CAEECB",
      color: "#38753A",
    },
    wrongChip: {
      backgroundColor: "rgba(227, 143, 143, 0.20)",
      color: "#D04444",
    },
    buttons: {
      display: "flex",
      width: "207px",
      padding: "12px 26px",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      marginTop: "20px",
      borderRadius: "15px",
    },
    pendingButtons: {
      borderRadius: "15px",
      color: "#FCFCFC",
      fontSize: "12px",
      fontWeight: "400",
    },
    container: {
      borderRadius: "18px",
      backgroundColor: "#F9FAFB",
      width: "100%",
      padding: "10px",
    },
    titleText: {
      fontFamily: "Poppins",
      color: "#737589",
      fontSize: "24px",
      fontWeight: "600",
      textAlign: "center",
    },
  };

  return (
    <div>
      <SelectPathologyAndMedicalGroup
        endpoint="patient"
        preferencePathology="patient_panel_default_pathology"
        preferenceMedicalGroup="patient_panel_default_medical_group"
        setSelectedPathology={setSelectedPathology}
        setSelectedMedicalGroup={setSelectedMedicalGroup}
        selectedPathology={selectedPathology}
        selectedMedicalGroup={selectedMedicalGroup}
      />

      <Box marginTop={"10px"}>
        <MaterialTable
          columns={columns}
          data={patients}
          title="Institutions"
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: "error",
                  children: "Error al traer los datos del servidor",
                }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={rowCount}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
          }}
        />
        <div
          align="right"
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            sx={[
              styles.buttons,
              { color: "#3657D9", background: "white", width: "155px" },
            ]}
            color="primary"
            onClick={handleExportData}
            endIcon={<img src="export.png" />}
            variant="contained"
          >
            Exportar
          </Button>
        </div>
      </Box>
      <Box sx={styles.container}>
        <Box sx={styles.titleText}>{t("patient_signup_in_progress")}</Box>
        <MaterialTable
          columns={columns2}
          data={pendingPatients}
          muiToolbarAlertBannerProps={
            isError2
              ? {
                  color: "error",
                  children: t("error_fetching_data"),
                }
              : undefined
          }
          onPaginationChange={setPagination2}
          rowCount={rowCount2}
          state={{
            pagination: pagination2,
            showAlertBanner: isError2,
          }}
        />
      </Box>
    </div>
  );
};

export default withMessages(PatientsIndexPage);